<template>
  <div>
    <b-card-code :title="$t('Manage') + ' ' + $t('Scenes')">
      <!-- search input -->
      <div class="custom-search d-flex justify-content-between">
        <div>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            v-b-modal.modal-create-scene
            @click="resetNewScene()"
          >
            <feather-icon icon="PlusIcon" class="mr-50" />
            <span class="align-middle">{{$t('Add')}}</span>
          </b-button>
        </div>
        <b-form-group>
          <div class="d-flex align-items-center">
            <label class="mr-1">{{$t('Search')}}</label>
            <b-form-input v-model="searchTerm" :placeholder="$t('Search')" type="text" class="d-inline-block" />
          </div>
        </b-form-group>
      </div>

      <!-- table -->
      <vue-good-table
        :columns="columns"
        :isLoading="loadingScenes"
        :rows="scenes"
        :search-options="{
          enabled: true,
          externalQuery: searchTerm,
        }"
        :pagination-options="{
          enabled: true,
          perPage: pageLength,
        }"
      >
        <!-- Slot: Table Column -->
        <template
          slot="table-column"
          slot-scope="props"
        >
          <span
            v-if="props.column.label ==='Name'"
            class="text-nowrap"
          >
            {{ $t('Name') }}
          </span>
          <span
            v-else-if="props.column.label ==='Parking Lot'"
            class="text-nowrap"
          >
            {{ $t('Parking Lot') }}
          </span>
          <span
            v-else-if="props.column.label ==='Action'"
            class="text-nowrap"
          >
            {{ $t('Action') }}
          </span>
          <span v-else>
            {{ props.column.label }}
          </span>
        </template>

        <template slot="table-row" slot-scope="props">
          <!-- Column: No -->
          <span v-if="props.column.field === 'index'">
            {{ props.index + 1 }}
          </span>
          <!-- Column: Snapshot -->
          <span v-else-if="props.column.field === 'snapshotExisting'">
            <feather-icon
              v-if="isBase64(props.row.snapshot)"
              icon="ImageIcon"
              size="33"
              style="color: #7367f0"
            />
            <feather-icon
              v-else
              icon="ImageIcon"
              size="33"
              style="color: lightgray"
            />
          </span>

          <!-- Column: Action -->
          <span v-else-if="props.column.field === 'action'" class="action-btns">
            <b-button
              v-ripple.400="'rgba(40, 199, 111, 0.15)'"
              variant="flat-primary"
              size="sm"
              @click="setSelectedScene(props.row)"
            >
              <feather-icon icon="ImageIcon" class="mr-50" />
              <span class="align-middle">{{$t('Spaces')}}</span>
            </b-button>
            <b-button
              v-ripple.400="'rgba(40, 199, 111, 0.15)'"
              variant="flat-success"
              v-b-modal.modal-edit-scene
              size="sm"
              @click="setEditScene(props.row)"
            >
              <feather-icon icon="Edit2Icon" class="mr-50" />
              <span class="align-middle">{{$t('Edit')}}</span>
            </b-button>
            <b-button
              v-ripple.400="'rgba(40, 199, 111, 0.15)'"
              variant="flat-danger"
              size="sm"
              @click="removeScene(props.row)"
            >
              <feather-icon icon="TrashIcon" class="mr-50" />
              <span class="align-middle">{{$t('Delete')}}</span>
            </b-button>
          </span>

          <!-- Column: Common -->
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>

        <!-- pagination -->
        <template slot="pagination-bottom" slot-scope="props">
          <div class="d-flex justify-content-between flex-wrap">
            <div class="d-flex align-items-center mb-0 mt-1">
              <span class="text-nowrap"> {{$t('Showing')}} 1 {{$t('to')}} </span>
              <b-form-select
                v-model="pageLength"
                :options="['3', '5', '10']"
                class="mx-1"
                @input="
                  (value) => props.perPageChanged({ currentPerPage: value })
                "
              />
              <span class="text-nowrap"> {{$t('of')}} {{ props.total }} {{$t('entries')}} </span>
            </div>
            <div>
              <b-pagination
                :value="1"
                :total-rows="props.total"
                :per-page="pageLength"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @input="(value) => props.pageChanged({ currentPage: value })"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </div>
          </div>
        </template>
      </vue-good-table>
    </b-card-code>

    <!-- create scene modal-->
    <b-modal
      id="modal-create-scene"
      cancel-variant="outline-secondary"
      :ok-title="$t('Create')"
      :cancel-title="$t('Cancel')"
      :title="$t('Create') + ' ' + $t('Scene')"
      centered
      no-close-on-backdrop
      ref="create-scene-modal"
      :ok-disabled="createLoading"
      @ok="createScene"
      @cancel="onCreateModalClosed"
      @hidden="onCreateModalClosed"
    >
      <validation-observer ref="createSceneRules">
        <b-form>
          <b-form-group>
            <validation-provider
              #default="{ errors }"
              name="Name"
              rules="required"
            >
              <label for="name">{{$t('Name')}}</label>
              <b-form-input
                v-model="newScene.sceneName"
                id="name"
                type="text"
                :state="errors.length > 0 ? false : null"
                placeholder="Scene name"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group>
            <validation-provider
              #default="{ errors }"
              name="Principal"
            >
              <label for="principal">{{$t('Principal')}}</label>
              <v-select
                id="principal"
                v-model="selectedPrincipal"
                :options="principals"
                label="name"
                @input="onPrincipalChange('create')"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group>
            <validation-provider
              #default="{ errors }"
              name="ParkingLot"
              rules="required"
            >
              <label for="parkingLot">{{$t('Parking Lot')}}</label>
              <v-select
                id="parkingLot"
                :value="newScene.parkingLot"
                @input="onParkinglotChangeCreate"
                :options="parkingLots"
                label="name"
                :disabled="selectedPrincipal == null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group>
            <label for="address">{{$t('Address')}}</label>
            <!-- <b-form-input
              v-model="newScene.address"
              id="address"
              type="text"
              placeholder="Address"
              style="margin-bottom: 5px"
            /> -->
            <b-input-group>
              <gmap-autocomplete
                ref="gmapAutocompleteCreate"
                class="form-control"
                :placeholder="$t('Search address')"
                @place_changed="usePlace"
                :options="{
                  fields: [
                    'geometry',
                    'formatted_address',
                    'address_components',
                  ],
                }"
                :select-first-on-enter="true"
              >
              </gmap-autocomplete>
            </b-input-group>
            <GmapMap
              ref="mapRefCreate"
              :center="centerMapCreate"
              :zoom="18"
              style="width: 100%; height: 400px"
              @click="handleMapClickOnCreate"
            >
              <GmapMarker
                :key="index"
                v-for="(m, index) in markers"
                :position="m.position"
                :clickable="true"
                :draggable="true"
                @click="center = m.position"
              />
            </GmapMap>
          </b-form-group>

          <!-- <b-form-group>
            <validation-provider
              #default="{ errors }"
              name="DeviceID"
              rules="guid"
            >
              <label for="deviceId">Device ID</label>
              <b-form-input
                v-model="newScene.deviceId"
                id="deviceId"
                type="text"
                :state="errors.length > 0 ? false : null"
                placeholder="Device ID"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group> -->
        </b-form>
      </validation-observer>
    </b-modal>

    <!-- edit scene modal-->
    <b-modal
      id="modal-edit-scene"
      cancel-variant="outline-secondary"
      :ok-title="$t('Update')"
      :cancel-title="$t('Cancel')"
      :title="$t('Update') + ' ' + $t('Scene')"
      centered
      no-close-on-backdrop
      ref="edit-scene-modal"
      :ok-disabled="editLoading"
      @ok="updateScene"
      @cancel="onEditModalClosed"
      @hidden="onEditModalClosed"
    >
      <validation-observer ref="editSceneRules">
        <b-form>
          <b-form-group>
            <validation-provider
              #default="{ errors }"
              name="Name"
              rules="required"
            >
              <label for="name">{{$t('Name')}}</label>
              <b-form-input
                v-model="editScene.newSceneName"
                id="name"
                type="text"
                :state="errors.length > 0 ? false : null"
                placeholder="Scene name"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group>
            <validation-provider
              #default="{ errors }"
              name="Principal"
            >
              <label for="principal">{{$t('Principal')}}</label>
              <v-select
                id="principal"
                v-model="selectedPrincipal"
                :options="principals"
                label="name"
                @input="onPrincipalChange('edit')"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group>
            <validation-provider
              #default="{ errors }"
              name="ParkingLot"
              rules="required"
            >
              <label for="parkingLot">{{$t('Parking Lot')}}</label>
              <v-select
                id="parkingLot"
                :value="editScene.newParkingLot"
                @input="onParkinglotChangeEdit"
                :options="parkingLots"
                label="name"
                :disabled="selectedPrincipal == null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group>
            <label for="address">{{$t('Address')}}</label>
            <!-- <b-form-input
              v-model="editScene.address"
              id="address"
              type="text"
              placeholder="Address"
              style="margin-bottom: 5px"
            /> -->
            <b-input-group>
              <gmap-autocomplete
                class="form-control"
                :placeholder="$t('Search address')"
                ref="gmapAutocompleteEdit"
                @place_changed="usePlace"
                :options="{
                  fields: [
                    'geometry',
                    'formatted_address',
                    'address_components',
                  ],
                }"
                :select-first-on-enter="true"
              >
              </gmap-autocomplete>
            </b-input-group>

            <GmapMap
              ref="mapRefEdit"
              :center="centerMapEdit"
              :zoom="18"
              style="width: 100%; height: 400px"
              @click="handleMapClickOnEdit"
            >
              <GmapMarker
                :key="index"
                v-for="(m, index) in markers"
                :position="m.position"
                :clickable="true"
                :draggable="true"
                @click="center = m.position"
              />
            </GmapMap>
          </b-form-group>
          <!-- <b-form-group>
            <label for="deviceId">Device ID</label>
            <b-form-input
              v-model="editScene.deviceId"
              id="deviceId"
              type="text"
              placeholder="Device ID"
            />
          </b-form-group> -->
        </b-form>
      </validation-observer>
    </b-modal>

    <!-- manage snapshot/space modal-->
    <b-modal
      id="snapshot-space-edit"
      cancel-variant="outline-secondary"
      :ok-title="$t('Save')"
      :cancel-title="$t('Cancel')"
      :title="$t('Manage') + ' ' + $t('Spaces')"
      centered
      no-close-on-backdrop
      ref="snapshot-space-edit"
      :dialog-class="spacesViewerModalClass"
      :ok-disabled="editCoordsLoading"
      @cancel="onSnapshotManageModalClosed"
      @hidden="onSnapshotManageModalClosed"
      @ok="updateSpaceCoords"
      :v-if="rightSpacesCollected.length > 0"
    >
      <validation-observer ref="snapshotSpaceRules">
        <b-form>
          <b-form-group>
            <div class="align-items-baseline d-flex justify-content-between">
              <label for="Snapshot">{{$t('Snapshot')}}</label>
              <b-button
                v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                variant="flat-success"
                :class="`btn-icon ${snapshotViewMax ? 'max-icon' : ''}`"
                v-b-tooltip.hover.top="
                  snapshotViewMax ? $t('Minimize snapshot') : $t('Maximize snapshot')
                "
                @click="maxMinSnapshot"
              >
                <feather-icon
                  style="width: 20px; height: 20px"
                  :icon="snapshotViewMax ? 'MinimizeIcon' : 'MaximizeIcon'"
                />
              </b-button>
            </div>
            <div v-if="selectedScene.snapshot">
              <!-- overlay on fullscreen snapshot -->
              <div
                v-if="snapshotViewMax"
                style="
                  position: fixed;
                  top: 0;
                  left: 0;
                  width: 100%;
                  height: 100%;
                  background: #f8f8f8;
                "
              >
              </div>
              <div
                v-if="handlingSnapshotSize"
                style="
                  position: fixed;
                  top: 0;
                  left: 0;
                  width: 100%;
                  height: 100%;
                  background: #f8f8f8;z-index: 2;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                "
              >
                <b-spinner label="Loading..." style="width: 100px; height: 100px" />
              </div>

              <div
                :class="
                  'snapshot-wrapper ' +
                  (snapshotViewMax
                    ? 'snapshot-wrapper-maximized'
                    : 'snapshot-wrapper-minimized')
                "
              >
                <b-img
                  :class="
                    snapshotViewMax
                      ? 'snapshot-img-maximized'
                      : 'snapshot-img-minimized'
                  "
                  id="snapshot-img"
                  thumbnail
                  fluid
                  :src="getSnapshotImage()"
                  ref="selectedSnapshot"
                  @load="snapshotLoad"
                  @error="snapshotLoadFailed"
                />
              </div>
              <div v-for="space in rightSpacesCollected" :key="space.id">
                <div
                  v-if="
                    snapshotLoadDone &&
                    (selectedScene.space.name == 'All' ||
                      selectedScene.space.id == space.id) &&
                    space.coords.length == 4
                  "
                >
                  <fabric-canvas
                    :height="snapshotHeight + 10"
                    :width="snapshotWidth + 10"
                    :class="
                      'fabric-canvas-itself ' +
                      (snapshotViewMax
                        ? 'canvas-maximized'
                        : 'canvas-minimized')
                    "
                    :id="'canvas' + space.id"
                    :ref="'fabricCanvas' + space.id"
                    :allowAutoScrolling="true"
                    :controlsAboveOverlay="true"
                    :stateful="false"
                    @object-moving="canvasObjMoving"
                  >
                    <template>
                      <!-- space fill -->
                      <!-- <fabric-polygon
                      :id="'polygon' + space.id"
                      :points="convertCoordsToPoints(space.coords)"
                      :fill="space.zone ? space.zone.name : lineFill"
                      :opacity="0.3"
                      :originX="'center'"
                      :originY="'center'"
                    ></fabric-polygon> -->

                      <!-- lines -->
                      <fabric-line
                        :key="'topLine' + space.id"
                        :id="'topLine' + space.id"
                        :x1="space.coords[0].xvalue"
                        :y1="space.coords[0].yvalue"
                        :x2="space.coords[1].xvalue"
                        :y2="space.coords[1].yvalue"
                        :fill="space.zone ? space.zone.name : lineFill"
                        :stroke="space.zone ? space.zone.name : lineFill"
                        :strokeWidth="strokeWidth"
                        :selectable="false"
                        :evented="false"
                        :originX="'center'"
                        :originY="'center'"
                      ></fabric-line>
                      <fabric-line
                        :key="'leftLine' + space.id"
                        :id="'leftLine' + space.id"
                        :x1="space.coords[0].xvalue"
                        :y1="space.coords[0].yvalue"
                        :x2="space.coords[2].xvalue"
                        :y2="space.coords[2].yvalue"
                        :fill="space.zone ? space.zone.name : lineFill"
                        :stroke="space.zone ? space.zone.name : lineFill"
                        :strokeWidth="strokeWidth"
                        :selectable="false"
                        :evented="false"
                        :originX="'center'"
                        :originY="'center'"
                      ></fabric-line>
                      <fabric-line
                        :key="'rightLine' + space.id"
                        :id="'rightLine' + space.id"
                        :x1="space.coords[1].xvalue"
                        :y1="space.coords[1].yvalue"
                        :x2="space.coords[3].xvalue"
                        :y2="space.coords[3].yvalue"
                        :fill="space.zone ? space.zone.name : lineFill"
                        :stroke="space.zone ? space.zone.name : lineFill"
                        :strokeWidth="strokeWidth"
                        :selectable="false"
                        :evented="false"
                        :originX="'center'"
                        :originY="'center'"
                      ></fabric-line>
                      <fabric-line
                        :key="'bottomLine' + space.id"
                        :id="'bottomLine' + space.id"
                        :x1="space.coords[2].xvalue"
                        :y1="space.coords[2].yvalue"
                        :x2="space.coords[3].xvalue"
                        :y2="space.coords[3].yvalue"
                        :fill="space.zone ? space.zone.name : lineFill"
                        :stroke="space.zone ? space.zone.name : lineFill"
                        :strokeWidth="strokeWidth"
                        :selectable="false"
                        :evented="false"
                        :originX="'center'"
                        :originY="'center'"
                      ></fabric-line>

                      <!-- circles -->
                      <fabric-circle
                        :key="'topLeft' + space.id"
                        :id="'topLeft' + space.id"
                        :left.sync="space.coords[0].xvalue"
                        :top.sync="space.coords[0].yvalue"
                        :strokeWidth="strokeWidth"
                        :radius="circleRadius"
                        :fill="'#fff'"
                        :stroke="'#666'"
                        :originX="'center'"
                        :originY="'center'"
                        :hasControls="false"
                        :selectable="selectedScene.space.name != 'All'"
                        :hoverCursor="
                          selectedScene.space.name != 'All'
                            ? 'all-scroll'
                            : 'normal'
                        "
                      ></fabric-circle>
                      <fabric-circle
                        :key="'topRight' + space.id"
                        :id="'topRight' + space.id"
                        :left.sync="space.coords[1].xvalue"
                        :top.sync="space.coords[1].yvalue"
                        :strokeWidth="strokeWidth"
                        :radius="circleRadius"
                        :fill="'#fff'"
                        :stroke="'#666'"
                        :originX="'center'"
                        :originY="'center'"
                        :hasControls="false"
                        :selectable="selectedScene.space.name != 'All'"
                        :hoverCursor="
                          selectedScene.space.name != 'All'
                            ? 'all-scroll'
                            : 'normal'
                        "
                      ></fabric-circle>
                      <fabric-circle
                        :key="'bottomLeft' + space.id"
                        :id="'bottomLeft' + space.id"
                        :left.sync="space.coords[2].xvalue"
                        :top.sync="space.coords[2].yvalue"
                        :strokeWidth="strokeWidth"
                        :radius="circleRadius"
                        :fill="'#fff'"
                        :stroke="'#666'"
                        :originX="'center'"
                        :originY="'center'"
                        :hasControls="false"
                        :selectable="selectedScene.space.name != 'All'"
                        :hoverCursor="
                          selectedScene.space.name != 'All'
                            ? 'all-scroll'
                            : 'normal'
                        "
                      ></fabric-circle>
                      <fabric-circle
                        :key="'bottomRight' + space.id"
                        :id="'bottomRight' + space.id"
                        :left.sync="space.coords[3].xvalue"
                        :top.sync="space.coords[3].yvalue"
                        :strokeWidth="strokeWidth"
                        :radius="circleRadius"
                        :fill="'#fff'"
                        :stroke="'#666'"
                        :originX="'center'"
                        :originY="'center'"
                        :hasControls="false"
                        :selectable="selectedScene.space.name != 'All'"
                        :hoverCursor="
                          selectedScene.space.name != 'All'
                            ? 'all-scroll'
                            : 'normal'
                        "
                      ></fabric-circle>

                      <!-- License Plate -->
                      <fabric-text
                        :key="'LicensePlate' + space.id"
                        :id="'LicensePlate' + space.id"
                        :text="getLicensePlateBySpace(space)"
                        :fontSize="16"
                        :fill="
                          getZoneBySpace(space)
                            ? getZoneBySpace(space).name
                            : lineFill
                        "
                        :fontWeight="'bold'"
                        textBackgroundColor="rgb(255 255 255 / 40%)"
                        :left="space.coords[2].xvalue"
                        :top="space.coords[2].yvalue"
                        :hasControls="false"
                        :selectable="false"
                        hoverCursor="normal"
                      ></fabric-text>

                      <!-- space name -->
                      <fabric-text
                        :id="'spaceName' + space.id"
                        :text="space.name"
                        :fontSize="16"
                        :fill="space.zone ? space.zone.name : lineFill"
                        :fontWeight="'bold'"
                        textBackgroundColor="rgb(255 255 255 / 40%)"
                        :left="space.coords[2].xvalue"
                        :top="
                          space.coords[2].yvalue +
                          (getLicensePlateBySpace(space) == '' ? 0 : 17)
                        "
                        :hasControls="false"
                        :selectable="false"
                        hoverCursor="normal"
                      ></fabric-text>
                    </template>
                  </fabric-canvas>
                </div>
              </div>
            </div>
            <p
              v-else-if="nonExistDevicesDataRecently"
              style="
                font-size: 14px;
                margin-bottom: 0;
                color: #ff5722;
                text-align: center;
              "
            >
              {{$t('Device is not connected')}}
            </p>
            <div v-else class="d-flex justify-content-center mb-1">
              <b-spinner label="Loading..." />
            </div>
          </b-form-group>

          <div
            v-show="selectedScene.space.name != 'All'"
            style="display: flex; justify-content: flex-end"
          >
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="outline-warning"
              size="sm"
              @click="resetCoords()"
            >
              <feather-icon icon="RefreshCcwIcon" class="mr-50" />
              <span class="align-middle">{{$t('Reset coords')}}</span>
            </b-button>
          </div>
          <!-- spaces select -->
          <b-form-group>
            <validation-provider
              #default="{ errors }"
              name="Space"
              rules="required"
            >
              <label for="space">{{$t('Space')}}</label>
              <v-select
                :style="'z-index: ' + (snapshotViewMax ? '-2' : '0')"
                id="space"
                v-model="selectedScene.space"
                :options="spaces"
                @input="onSelectedSpaceChange"
                :clearable="false"
                label="name"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <!-- coords inputs -->
          <b-row
            v-if="
              false &&
              selectedScene.space.name != 'All' &&
              selectedScene.space.coords.length == 4
            "
          >
            <b-col cols="6">
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="TopLeftX"
                  rules="required|integer"
                >
                  <label for="TopLeftX">Top Left X:</label>
                  <b-form-input
                    v-model="selectedScene.space.coords[0].xvalue"
                    id="name"
                    type="number"
                    :state="errors.length > 0 ? false : null"
                    placeholder="TopLeftX"
                    :formatter="integerFormatter"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="TopLeftY"
                  rules="required|integer"
                >
                  <label for="TopLeftY">Top Left Y:</label>
                  <b-form-input
                    v-model="selectedScene.space.coords[0].yvalue"
                    id="name"
                    type="number"
                    :state="errors.length > 0 ? false : null"
                    placeholder="TopLeftY"
                    :formatter="integerFormatter"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="TopRightX"
                  rules="required|integer"
                >
                  <label for="TopRightX">Top Right X:</label>
                  <b-form-input
                    v-model="selectedScene.space.coords[1].xvalue"
                    id="name"
                    type="number"
                    :state="errors.length > 0 ? false : null"
                    placeholder="TopRightX"
                    :formatter="integerFormatter"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="TopRightY"
                  rules="required|integer"
                >
                  <label for="TopRightY">Top Right Y:</label>
                  <b-form-input
                    v-model="selectedScene.space.coords[1].yvalue"
                    id="name"
                    type="number"
                    :state="errors.length > 0 ? false : null"
                    placeholder="TopRightY"
                    :formatter="integerFormatter"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col cols="6">
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="BottomLeftX"
                  rules="required|integer"
                >
                  <label for="BottomLeftX">Bottom Left X:</label>
                  <b-form-input
                    v-model="selectedScene.space.coords[2].xvalue"
                    id="name"
                    type="number"
                    :state="errors.length > 0 ? false : null"
                    placeholder="BottomLeftX"
                    :formatter="integerFormatter"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="BottomLeftY"
                  rules="required|integer"
                >
                  <label for="BottomLeftY">Bottom Left Y:</label>
                  <b-form-input
                    v-model="selectedScene.space.coords[2].yvalue"
                    id="name"
                    type="number"
                    :state="errors.length > 0 ? false : null"
                    placeholder="BottomLeftY"
                    :formatter="integerFormatter"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="BottomRightX"
                  rules="required|integer"
                >
                  <label for="BottomRightX">Bottom Right X:</label>
                  <b-form-input
                    v-model="selectedScene.space.coords[3].xvalue"
                    id="name"
                    type="number"
                    :state="errors.length > 0 ? false : null"
                    placeholder="BottomRightX"
                    :formatter="integerFormatter"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="BottomRightY"
                  rules="required|integer"
                >
                  <label for="BottomRightY">Bottom Right Y:</label>
                  <b-form-input
                    v-model="selectedScene.space.coords[3].yvalue"
                    id="name"
                    type="number"
                    :state="errors.length > 0 ? false : null"
                    placeholder="BottomRightY"
                    :formatter="integerFormatter"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-modal>
  </div>
</template>

<script>
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import {
  BAvatar,
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  BButton,
  BModal,
  BForm,
  VBModal,
  BInputGroup,
  BInputGroupAppend,
  BInputGroupPrepend,
  BImg,
  BRow,
  BCol,
  BSpinner,
  VBTooltip,
} from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";
import store from "@/store/index";
import Ripple from "vue-ripple-directive";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, email, confirmed, password } from "@validations";
import vSelect from "vue-select";
import { gmapApi } from "vue2-google-maps";
import vueFabricWrapper from "vue-fabric-wrapper";

export default {
  components: {
    BCardCode,
    VueGoodTable,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BButton,
    BModal,
    BForm,
    vSelect,
    ValidationProvider,
    ValidationObserver,
    BInputGroup,
    BInputGroupAppend,
    BInputGroupPrepend,
    BImg,
    BRow,
    BCol,
    BSpinner,
    FabricCanvas: vueFabricWrapper.FabricCanvas,
    FabricCircle: vueFabricWrapper.FabricCircle,
    FabricLine: vueFabricWrapper.FabricLine,
  },
  directives: {
    "b-modal": VBModal,
    "b-tooltip": VBTooltip,
    Ripple,
  },
  data() {
    return {
      selectedPrincipal: null,
      selectedParkingLot: null,
      currentModal: null,
      mapCurrentPlace: null,
      description: "",

      snapshotLoadDone: false,
      firstLoaded: false,
      rightSpacesCollected: [],
      resolutionRateActual: 1,
      resolutionRate: 1,
      prevResolutionRate: 1,
      editCoordsLoading: false,

      snapshotWidth: 500,
      snapshotHeight: 500,

      tempValueCoords: [
        { xvalue: 140, yvalue: 105 },
        { xvalue: 245, yvalue: 105 },
        { xvalue: 140, yvalue: 180 },
        { xvalue: 245, yvalue: 180 },
      ],

      strokeWidth: 3,
      lineFill: "red",
      lineStroke: "red",
      circleRadius: 5,

      markers: [],
      showMap: false,
      centerMapCreate: { lat: 45.508, lng: -73.587 },
      centerMapEdit: { lat: 45.508, lng: -73.587 },
      newScene: {
        sceneName: "",
        gpsLocation: "",
        deviceId: "",
        parkingLot: "",
      },
      selectedScene: {
        id: null,
        space: {
          name: "All",
        },
        coords: [],
        gpsLocation: "",
        deviceId: "",
        parkingLot: "",
      },
      editScene: {
        newSceneName: "",
        gpsLocation: "",
        deviceId: "",
        parkingLot: "",
      },
      createLoading: false,
      editLoading: false,
      pageLength: 10,
      dir: false,
      columns: [
        // {
        //   label: "No",
        //   field: "index",
        // },
        {
          label: "Name",
          field: "name",
        },
        // {
        //   label: "GPS Location",
        //   field: "gpsLocation",
        // },
        {
          label: "Parking Lot",
          field: "parkingLot.name",
        },
        // {
        //   label: "Device Id",
        //   field: "deviceId",
        // },
        // {
        //   label: "Snapshot",
        //   field: "snapshotExisting",
        //   // width: "120px",
        //   tdClass: "text-center",
        //   thClass: "text-center",
        //   sortable: false,
        // },
        {
          label: "Action",
          field: "action",
          // width: "250px",
        },
      ],
      rows: [],
      searchTerm: "",
      commandGuid: null,
      snapshotInterval: null,

      snapshotViewMax: false,
      spacesViewerModalClass: "minimized",
      naturalWidth: 0,
      naturalHeight: 0,
      viewingWidth: 0,
      viewingHeight: 0,

      viewingMaxWidth: 1200,
      viewingMaxHeight: 680,

      nonExistDevicesDataRecently: false,
      handlingSnapshotSize: false,
    };
  },
  computed: {
    g_selectedPrincipal() {
      return this.$store.state.global.selectedPrincipal;
    },
    g_selectedParkingLot() {
      return this.$store.state.global.selectedParkingLot;
    },
    scenes() {
      const filtered = this.$store.state.scene.scenes.filter((sc) => {
        return (
          (this.g_selectedParkingLot == null ||
            sc.parkingLot?.id == this.g_selectedParkingLot?.id) &&
          (this.g_selectedPrincipal == null ||
            sc.parkingLot?.principal?.id == this.g_selectedPrincipal?.id)
        );
      });
      return filtered;
    },
    spaces() {
      if (this.selectedScene?.id) {
        const filtered = this.$store.state.space.spaces.filter((sp) => {
          const found = sp.sceneSpaces.find(
            (ss) => ss.scene?.id == this.selectedScene?.id
          );

          if (found) {
            return true;
          } else {
            return false;
          }
        });

        filtered.unshift({
          name: "All",
        });
        if (this.firstLoaded == false) {
          this.rightSpaces(filtered, true);
        }
        return filtered;
      } else {
        return this.$store.state.space.spaces;
      }
    },
    originSpaces() {
      return this.$store.state.space.originSpaces;
    },
    loadingScenes() {
      return this.$store.state.scene.loadingScenes;
    },
    principals() {
      return this.$store.state.principal.principals;
    },
    parkingLots() {
      if (this.selectedPrincipal) {
        return this.$store.state.parkingLot.parkingLots.filter(
          (pl) => pl.principal?.id == this.selectedPrincipal?.id
        );
      }
      return this.$store.state.parkingLot.parkingLots;
    },
    loadingParkingLots() {
      return this.$store.state.parkingLot.loadingParkingLots;
    },
    zones() {
      return this.$store.state.zone.zones;
    },
    occupancys() {
      return this.$store.state.device.occupancys;
    },
    google: gmapApi,
  },
  async created() {
    await this.$store.dispatch("scene/get", { bvToast: this.$bvToast });
    await this.$store.dispatch("parkingLot/get", this.$bvToast);
    await this.$store.dispatch("space/get", this.$bvToast);
    await this.$store.dispatch("zone/get", this.$bvToast);
    await this.$store.dispatch("device/getOccupancys", this.$bvToast);
  },
  mounted() {},
  beforeDestroy() {
    this.stopSnapshotInterval(this.snapshotInterval);
  },
  methods: {
    onCreateModalClosed(){
      this.markers = []
    },
    onEditModalClosed(){
      this.markers = []
    },
    onPrincipalChange(action) {
      console.log('action', action)
      if(action == 'create') {
        this.newScene.parkingLot = null;
      } else {
        this.editScene.newParkingLot = null;
      }
    },
    onParkinglotChangeEdit(g_selectedParkingLot) {
      console.log('onParkinglotChangeEdit', g_selectedParkingLot)
      this.editScene.address = g_selectedParkingLot.address
      this.editScene.gpsLocation = g_selectedParkingLot.gpsLocation
      this.editScene.newParkingLot = g_selectedParkingLot

      const segs = g_selectedParkingLot.gpsLocation.split(", ")
      if (segs.length >= 2) {
        this.centerMapEdit = {
          lat: parseFloat(segs[0]),
          lng: parseFloat(segs[1]),
        };

        this.markers = [
          new this.google.maps.Marker({
            map: this.$refs.mapRefEdit,
            position: {
              lat: this.centerMapEdit.lat,
              lng: this.centerMapEdit.lng,
            },
            title: "Selected location",
          }),
        ];
      }
    },
    onParkinglotChangeCreate(g_selectedParkingLot) {
      console.log('onParkinglotChangeCreate', g_selectedParkingLot)
      this.newScene.address = g_selectedParkingLot.address
      this.newScene.gpsLocation = g_selectedParkingLot.gpsLocation
      this.newScene.parkingLot = g_selectedParkingLot

      setTimeout(()=> {
        this.$refs.gmapAutocompleteCreate.$el.value = g_selectedParkingLot.address
      }, 1000)

      const segs = g_selectedParkingLot.gpsLocation.split(", ")
      if (segs.length >= 2) {
        this.centerMapCreate = {
          lat: parseFloat(segs[0]),
          lng: parseFloat(segs[1]),
        };

        this.markers = [
          new this.google.maps.Marker({
            map: this.$refs.mapRefCreate,
            position: {
              lat: this.centerMapCreate.lat,
              lng: this.centerMapCreate.lng,
            },
            title: "Selected location",
          }),
        ];
      }
    },
    escKeyDown(e){
      console.log("escKeyDown", e)
    },
    getZoneBySpace(space) {
      return this.zones.find((item) => item.id == space.zoneType);
    },
    getLicensePlateBySpace(space) {
      const found = this.occupancys.find(
        (item) =>
          item.scene_id == this.selectedScene?.id && item.space_id == space.id
      );
      return found ? found.license_plate : "";
    },
    maxMinSnapshot() {
      const img = document.getElementById("snapshot-img");
      this.snapshotViewMax = !this.snapshotViewMax;

      if (this.snapshotViewMax) {
        this.spacesViewerModalClass = "maximized";
      } else {
        this.spacesViewerModalClass = "minimized";
      }

      const that = this;
      this.handlingSnapshotSize = true;

      setTimeout(function () {
        that.handleImageSize(img);
        that.handlingSnapshotSize = false;
      }, 1000);
    },
    isBase64(str) {
      if (str == null || str === "" || str.trim() === "") {
        return false;
      }
      if (str.indexOf("http://") > -1 || str.indexOf("https://") > -1) {
        return false;
      } else {
        return true;
      }
    },
    onSnapshotManageModalClosed() {
      console.log('onSnapshotManageModalClosed')
      // this.rightSpacesCollected = this.rightSpaces(this.originSpaces);
      this.$store.commit("space/SET_SPACES", "originSpaces");

      this.prevResolutionRate = 1;
      this.snapshotLoadDone = false;
      this.snapshotViewMax = false;
      this.spacesViewerModalClass = "minimized";

      this.stopSnapshotInterval(this.snapshotInterval);
    },
    convertCoordsToPoints(coords) {
      let points = [
        { x: coords[1].xvalue, y: coords[1].yvalue },
        { x: coords[0].xvalue, y: coords[0].yvalue },
        { x: coords[2].xvalue, y: coords[2].yvalue },
        { x: coords[3].xvalue, y: coords[3].yvalue },
      ];

      return points;
    },
    resetCoords() {
      if (this.selectedScene.space?.id) {
        const canvas =
          this.$refs["fabricCanvas" + this.selectedScene.space.id][0].canvas;
        const objs = canvas.getObjects();
        let index = 0;
        objs.map((obj) => {
          if (obj.isType("circle")) {
            obj.top = this.tempValueCoords[index].yvalue;
            obj.left = this.tempValueCoords[index].xvalue;
            index++;
            obj.setCoords();
          }
        });
      }
    },
    canvasObjMoving(e) {
      if (e) {
        var obj = e.target;
        // if object is too big ignore
        if (
          obj.currentHeight > obj.canvas.height ||
          obj.currentWidth > obj.canvas.width
        ) {
          return;
        }
        obj.setCoords();
        // top-left  corner
        if (obj.getBoundingRect().top < 0 || obj.getBoundingRect().left < 0) {
          obj.top = Math.max(obj.top, obj.top - obj.getBoundingRect().top);
          obj.left = Math.max(obj.left, obj.left - obj.getBoundingRect().left);
        }
        // bot-right corner
        if (
          obj.getBoundingRect().top + obj.getBoundingRect().height >
            obj.canvas.height ||
          obj.getBoundingRect().left + obj.getBoundingRect().width >
            obj.canvas.width
        ) {
          obj.top = Math.min(
            obj.top,
            obj.canvas.height -
              obj.getBoundingRect().height +
              obj.top -
              obj.getBoundingRect().top
          );
          obj.left = Math.min(
            obj.left,
            obj.canvas.width -
              obj.getBoundingRect().width +
              obj.left -
              obj.getBoundingRect().left
          );
        }
      }
    },
    snapshotLoadFailed(evt, b, c) {
      console.log("snapshotLoadFailed", evt, b, c);
    },
    snapshotLoad(evt) {
      const img = document.getElementById('snapshot-img');
      this.handleImageSize(img);
    },
    handleImageSize(img) {
      this.naturalWidth = img.naturalWidth;
      this.naturalHeight = img.naturalHeight;

      this.viewingWidth = img.width;
      this.viewingHeight = img.height;

      this.snapshotHeight = this.viewingHeight;
      this.snapshotWidth = this.viewingWidth;

      this.snapshotLoadDone = true;
      this.resolutionRateActual = this.viewingWidth / this.naturalWidth;

      this.resolutionRate = this.viewingWidth / this.naturalWidth;

      this.rightSpaces(this.spaces, true);
    },
    rightSpaces(spaces, shouldRun) {
      console.log("rightSpaces", shouldRun);
      if (shouldRun != true) {
        return;
      }

      let resolutionRate = this.resolutionRate;
      let snapshotHeight = this.viewingHeight;
      let snapshotWidth = this.viewingWidth;
      // if(this.snapshotViewMax) {
      //   resolutionRate = 1
      //   snapshotHeight = this.viewingMaxHeight
      //   snapshotWidth = this.viewingMaxWidth
      // }

      this.snapshotHeight = snapshotHeight;
      this.snapshotWidth = snapshotWidth;

      let tempSpaces = [];
      spaces.map((sp) => {
        // const sp = { ...item };
        if (sp.name != "All") {
          if (sp.coords.length != 4) {
            sp.coords = [];
            sp.manualCoords = true;

            for (let i = 0; i < 4; i++) {
              sp.coords.push({
                xvalue: this.tempValueCoords[i].xvalue,
                yvalue: this.tempValueCoords[i].yvalue,
              });
            }
          } else {
            for (let i = 0; i < 4; i++) {
              sp.coords[i].xvalue = sp.resolutionApplied
                ? (sp.coords[i].xvalue / this.prevResolutionRate) *
                  resolutionRate
                : sp.coords[i].xvalue * resolutionRate;

              sp.coords[i].yvalue = sp.resolutionApplied
                ? (sp.coords[i].yvalue / this.prevResolutionRate) *
                  resolutionRate
                : sp.coords[i].yvalue * resolutionRate;
            }
            sp.resolutionApplied = true;
          }

          tempSpaces.push(sp);
        }
      });

      this.prevResolutionRate = resolutionRate;
      this.rightSpacesCollected = tempSpaces;
    },
    integerFormatter(value) {
      return parseInt(value);
    },
    updateSpaceCoords(bvModalEvt) {
      bvModalEvt.preventDefault();

      this.$refs.snapshotSpaceRules.validate().then((success) => {
        if (success) {
          let coords = [];
          for (let i = 0; i < 4; i++) {
            coords.push({
              xvalue: parseInt(
                this.selectedScene.space.coords[i].xvalue / this.resolutionRate
              ),
              yvalue: parseInt(
                this.selectedScene.space.coords[i].yvalue / this.resolutionRate
              ),
            });
          }

          this.editCoordsLoading = true;
          this.$store
            .dispatch("space/store", {
              spaceName: this.selectedScene.space.name,
              parkingLot: this.selectedScene.space.parkingLot?.name,
              principal: this.selectedScene.space.parkingLot?.principal?.name,
              zone: this.selectedScene.space.zone?.name,
              coords: coords,
              newSpaceName: this.selectedScene.space.name,
              newParkingLot: this.selectedScene.space.parkingLot?.name,
              newPrincipal:
                this.selectedScene.space.parkingLot?.principal?.name,
            })
            .then(async (response) => {
              // this.$store.commit("space/EDIT_SPACE", {
              //   ...this.selectedScene.space,
              //   coords: response.data.coords,
              //   resolutionApplied: false,
              // });
              await this.$store.dispatch("space/get", this.$bvToast);

              this.editCoordsLoading = false;
              this.$refs["snapshot-space-edit"].hide();

              this.$bvToast.toast("Space coords updated successfully", {
                title: `Success`,
                variant: "success",
                solid: true,
              });
            })
            .catch((err) => {
              this.editCoordsLoading = false;

              this.$bvToast.toast(err.message, {
                title: `Error occured`,
                variant: "danger",
                solid: true,
                toaster: "b-toaster-bottom-right",
              });
            });
        }
      });
    },
    onSelectedSpaceChange(space) {
      if (space.name == "All") {
        return;
      }

      let tempSpaceCoords = [];
      if (space.coords.length != 4) {
        for (let i = 0; i < 4; i++) {
          tempSpaceCoords.push({
            xvalue: this.tempValueCoords[i].xvalue,
            yvalue: this.tempValueCoords[i].yvalue,
          });
        }

        this.selectedScene.space.coords = tempSpaceCoords;
      }
    },
    handleMapClickOnCreate(param) {
      const that = this;
      const geocoder = new this.google.maps.Geocoder();

      geocoder.geocode(
        {
          latLng: param.latLng,
        },
        function (results, status) {
          if (status == that.google.maps.GeocoderStatus.OK) {
            if (results[0]) {
              that.newScene.address = results[0].formatted_address;
              that.newScene.gpsLocation =
                param.latLng.lat() + ", " + param.latLng.lng();
              that.updateMarkers(
                that.$refs.mapRefCreate,
                param.latLng.lat(),
                param.latLng.lng()
              );
            }
          }
        }
      );
    },
    handleMapClickOnEdit(param) {
      const that = this;
      const geocoder = new this.google.maps.Geocoder();

      geocoder.geocode(
        {
          latLng: param.latLng,
        },
        function (results, status) {
          if (status == that.google.maps.GeocoderStatus.OK) {
            if (results[0]) {
              that.editScene.address = results[0].formatted_address;
              that.editScene.gpsLocation =
                param.latLng.lat() + ", " + param.latLng.lng();
              that.updateMarkers(
                that.$refs.mapRefEdit,
                param.latLng.lat(),
                param.latLng.lng()
              );
            }
          }
        }
      );
    },
    toggleMap(action) {
      this.showMap = !this.showMap;

      if (action == "onCreate") {
        this.markers = [
          new this.google.maps.Marker({
            map: this.$refs.mapRefCreate,
            position: this.centerMapCreate,
            title: "Selected location",
          }),
        ];
      }
    },
    createScene(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.$refs.createSceneRules.validate().then((success) => {
        if (success) {
          this.createLoading = true;
          this.$store
            .dispatch("scene/store", {
              ...this.newScene,
              parkingLot: this.newScene.parkingLot?.name,
              principal: this.newScene.parkingLot?.principal?.name,
              address: this.$refs.gmapAutocompleteCreate.$el.value
            })
            .then((response) => {
              this.$store.commit("scene/ADD_NEW_SCENE", response.data);
              this.createLoading = false;
              this.$refs["create-scene-modal"].hide();

              this.$bvToast.toast("Scene created successfully", {
                title: `Success`,
                variant: "success",
                solid: true,
              });
            })
            .catch((err) => {
              this.createLoading = false;

              this.$bvToast.toast(err.message, {
                title: `Error occured`,
                variant: "danger",
                solid: true,
                toaster: "b-toaster-bottom-right",
              });
            });
        }
      });
    },
    resetNewScene() {
      this.newScene = {
        sceneName: "",
        gpsLocation: "",
        deviceId: "",
        parkingLot: this.g_selectedParkingLot,
      };

      this.selectedPrincipal = this.g_selectedPrincipal;
      this.currentModal = "create";

      if(this.g_selectedParkingLot) {
        this.onParkinglotChangeCreate(this.g_selectedParkingLot)
      }
    },
    usePlace(place) {
      if (place) {
        const lat = place.geometry.location.lat();
        const lng = place.geometry.location.lng();

        if (this.currentModal == "create") {
          this.updateMarkers(this.$refs.mapRefCreate, lat, lng);
          this.centerMapCreate = { lat, lng };

          this.newScene.address = place.formatted_address;
          this.newScene.gpsLocation = lat + ", " + lng;
        } else {
          this.updateMarkers(this.$refs.mapRefEdit, lat, lng);
          this.centerMapEdit = { lat, lng };

          this.editScene.address = place.formatted_address;
          this.editScene.gpsLocation = lat + ", " + lng;
        }
      }
    },
    updateMarkers(mapRef, lat, lng) {
      const that = this;
      for (let i = 0; i < this.markers.length; i++) {
        const marker = this.markers[i];
        marker.setMap(null);
      }

      mapRef.$mapPromise.then((map) => {
        that.markers = [
          new that.google.maps.Marker({
            map: map,
            position: { lat, lng },
            title: "Selected location",
          }),
        ];
      });
    },
    getSnapshotImage() {
      return "data:image/jpg;base64, " + this.selectedScene.snapshot;
      if (this.isBase64(this.selectedScene.snapshot)) {
      } else {
        return this.selectedScene.snapshot;
      }
    },
    startSnapshotInterval() {
      this.snapshotInterval = setInterval(this.getSnapshot, 2000);
    },
    stopSnapshotInterval(interval) {
      window.clearInterval(interval);
    },
    getSnapshot() {
      const snapshotInterval = this.snapshotInterval;

      this.$store
        .dispatch("device/getSnapshot", {
          device_id: this.selectedScene.deviceId,
          commandGuid: this.commandGuid,
        })
        .then((response) => {
          // console.log("snapshotInterval: ", snapshotInterval, this.snapshotInterval, snapshotInterval == this.snapshotInterval)
          if (snapshotInterval !== this.snapshotInterval) {
            this.stopSnapshotInterval(snapshotInterval);
          } else {
            if (response.data) {
              this.selectedScene.snapshot = response.data;
              this.stopSnapshotInterval(snapshotInterval);
            }
          }
        })
        .catch((err) => {
          this.createLoading = false;

          this.$bvToast.toast(err.message, {
            title: `Error occured`,
            variant: "danger",
            solid: true,
            toaster: "b-toaster-bottom-right",
          });
        });
    },
    async setSelectedScene(row) {
      this.snapshotViewMax = false;
      this.spacesViewerModalClass = "minimized";

      this.selectedScene = {
        id: row.id,
        sceneName: row.name,
        parkingLot: row.parkingLot?.name,
        principal: row.parkingLot?.principal?.name,
        gpsLocation: row.gpsLocation,
        deviceId: row.deviceId,
        snapshot: row.snapshot,
        space: { name: "All" },
      };

      this.stopSnapshotInterval(this.snapshotInterval);
      this.snapshotInterval = null;

      if (row.deviceId) {
        this.nonExistDevicesDataRecently = false;

        // get occupancies
        await this.$store.dispatch("device/getOccupancys", this.$bvToast);

        // create command
        this.$store
          .dispatch("device/setCommand", { deviceStreamKey: row.deviceId })
          .then((response) => {
            if (response.data == false) {
              this.nonExistDevicesDataRecently = true;
            } else {
              this.commandGuid = response.data;
              this.startSnapshotInterval();
            }
          })
          .catch((err) => {
            this.createLoading = false;

            this.$bvToast.toast(err.message, {
              title: `Error occured`,
              variant: "danger",
              solid: true,
              toaster: "b-toaster-bottom-right",
            });
          });

        this.$refs["snapshot-space-edit"].show();
      } else {
        this.$bvToast.toast("This scene doesn't have device assigned", {
          title: `Not ready`,
          variant: "warning",
          solid: true,
          toaster: "b-toaster-top-right",
        });
      }
    },
    setEditScene(row) {
      this.currentModal = "update";
      this.showMap = false;
      this.editScene = {
        newSceneName: row.name,
        newParkingLot: row.parkingLot,
        newPrincipal: row.parkingLot?.principal?.name,
        sceneName: row.name,
        parkingLot: row.parkingLot?.name,
        principal: row.parkingLot?.principal?.name,
        gpsLocation: row.gpsLocation,
        deviceId: row.deviceId,
        snapshot: row.snapshot,
        address: row.address,
      };

      this.selectedPrincipal = this.g_selectedPrincipal;

      const segs = row.gpsLocation ? row.gpsLocation.split(", ") : [0, 0];
      if (row.gpsLocation && segs.length >= 2) {
        this.centerMapEdit = {
          lat: parseFloat(segs[0]),
          lng: parseFloat(segs[1]),
        };
      } else {
        this.centerMapEdit = this.centerMapCreate;
      }

      
      this.$gmapApiPromiseLazy().then(() => { 
        this.markers = [
          new this.google.maps.Marker({
            map: this.$refs.mapRefEdit,
            position: this.centerMapEdit,
            title: "Selected location",
          }),
        ];
      })     
      
      setTimeout(()=> {
        this.$refs.gmapAutocompleteEdit.$el.value = row.address
      }, 1000)
    },
    updateScene(bvModalEvt) {
      bvModalEvt.preventDefault();

      this.$refs.editSceneRules.validate().then((success) => {
        if (success) {
          this.editLoading = true;
          this.$store
            .dispatch("scene/store", {
              ...this.editScene,
              newParkingLot: this.editScene.newParkingLot?.name,
              newPrincipal: this.editScene.newParkingLot?.principal?.name,
              address: this.$refs.gmapAutocompleteEdit.$el.value
            })
            .then((response) => {
              this.$store.commit("scene/EDIT_SCENE", response.data);
              this.editLoading = false;
              this.$refs["edit-scene-modal"].hide();

              this.$bvToast.toast("Scene updated successfully", {
                title: `Success`,
                variant: "success",
                solid: true,
              });
            })
            .catch((err) => {
              this.editLoading = false;

              this.$bvToast.toast(err.message, {
                title: `Error occured`,
                variant: "danger",
                solid: true,
                toaster: "b-toaster-bottom-right",
              });
            });
        }
      });
    },
    removeScene(row) {
      this.$bvModal
        .msgBoxConfirm(this.$t('Are you sure?'), {
          title: this.$t('Please Confirm'),
          size: "sm",
          okVariant: "primary",
          okTitle: this.$t('Yes'),
          cancelTitle: this.$t('No'),
          cancelVariant: "outline-secondary",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value == true) {
            this.$store
              .dispatch("scene/remove", row)
              .then(async (response) => {
                this.$bvToast.toast("Scene deleted successfully", {
                  title: `Success`,
                  variant: "success",
                  solid: true,
                });

                await this.$store.dispatch("scene/get", {
                  bvToast: this.$bvToast,
                });
              })
              .catch((err) => {
                this.$bvToast.toast(err.message, {
                  title: `Error occured`,
                  variant: "danger",
                  solid: true,
                  toaster: "b-toaster-bottom-right",
                });
              });
          }
        });
    },
  },
};
</script>

<style lang="scss" >
@import "@core/scss/vue/libs/vue-good-table.scss";
@import "@core/scss/vue/libs/vue-select.scss";

.pac-container.pac-logo {
  z-index: 999999;
}

[dir] .vs__dropdown-toggle {
  border: 1px solid #d8d6de !important;
}

#snapshot-space-edit {
  padding-left: initial !important;

  .modal-dialog {
    max-width: fit-content !important;
  }
  .maximized {
    // max-width: fit-content !important;
    max-width: 1250px !important;

    margin: 25px auto !important;
    // max-width: 94% !important;
  }
  .minimized {
    max-width: 500px !important;
  }
}

.fabric-canvas-itself {
  position: absolute;
  top: 38px;
  // left: 23px
}

.fabric-canvas-itself.canvas-maximized {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  // width: 100%;
  // height: 100%;
}

// .snapshot-wrapper.snapshot-wrapper-maximized {
//     position: fixed;
//     background: #f8f8f8;
//     width: 100%;
//     left: 0;
//     top: 50%;
//     transform: translate(0, -50%);
//     // height: 100%;
// }

#snapshot-img.snapshot-img-maximized {
  object-fit: contain;
  border: 0;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  // width: 100%;
  height: 100%;
}

.max-icon {
  position: fixed!important; top: 15px; right: 15px; padding: 5px; z-index: 10000000;
}

.btn-icon {
  padding: 3px!important;
}
</style>
